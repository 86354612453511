<template>
  <article class="container-fluid">
    <h1 class="main-title mb-0">{{ $t("user.title") }}</h1>
    <v-alert v-if="!isDirector" color="warning" class="py-2" text outlined>
      <p class="mb-0 wx-typo-sm">{{ getUserManagementNotAllowedMessage() }}</p>
    </v-alert>

    <div :class="getUserListCssClasses()">
      <user-list />
    </div>
  </article>
</template>

<script>
import UserList from "../components/user/UserList";
import { mapGetters } from "vuex";
import UserService from "@/components/user/UserService";

export default {
  name: "UsersView",
  components: {
    UserList,
  },
  computed: {
    ...mapGetters("user", ["isDirector", "loggedInUserRole", "theme"]),
  },
  methods: {
    getUserListCssClasses() {
      return "mt-6";
    },
    getUserManagementNotAllowedMessage() {
      const yourRole = UserService.getDisplayRoleNameForValue(this.loggedInUserRole);
      const managementRole = this.$t("user.roles.management");
      return this.$t("user.cannotManageUser", { yourRole: yourRole, managementRole: managementRole });
    },
  },
};
</script>

<style lang="scss" scoped>
.cannotManageUserChip {
  color: black;
}
</style>
