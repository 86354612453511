<template>
  <fragment>
    <header class="action-container">
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="5" xl="4" class="order-2 order-sm-1">
          <wx-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            :label="$t('common.search')"
            hide-details
            single-line
            clearable
          />
        </v-col>
        <v-col
          v-if="isDirector"
          cols="12"
          sm="4"
          md="6"
          lg="7"
          xl="8"
          class="action-buttons order-1 order-sm-2 d-flex flex-wrap justify-end align-sm-end"
        >
          <wx-btn-standard :to="toUserCreation()" class="mr-2">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("user.buttonNew") }}
          </wx-btn-standard>
        </v-col>
      </v-row>
    </header>

    <wx-data-table
      :headers="computedUserColumns"
      :items="computedUsers"
      :items-per-page="usersPerPage"
      :search="search"
      :filterable="true"
      :sort-by="['name', 'email', 'role', 'admin', 'tilelytics', 'statusLabel']"
      :sort-desc="[false, false, false, false, false, false]"
      :no-results-text="this.$t('common.noMatchingRecords')"
      :hide-default-footer="computedUsers.length <= usersPerPage"
      :footer-props="{
        itemsPerPageText: this.$t('user.listing.itemsPerPage'),
        itemsPerPageAllText: this.$t('common.listing.showAllOption'),
      }"
      :title="getRowTitle()"
      @click:row="redirectToUserEdition"
      mobile-breakpoint="sm"
      class="wx-panel"
    >
      <template #[`item.name`]="{ item }">
        <v-avatar color="secondary" size="36" class="ma-2">{{ getAvatarText(item.name) }}</v-avatar>
        <span v-if="isActiveUser(item)">{{ item.name }}</span>
        <span v-else class="inactive-user">{{ item.name }}</span>
      </template>
      <template #[`item.email`]="{ item }">
        <span v-if="isActiveUser(item)">{{ item.email }}</span>
        <span v-else class="inactive-user">{{ item.email }}</span>
      </template>
      <template #[`item.role`]="{ item }">
        <span v-if="isActiveUser(item)">{{ item.role }}</span>
        <span v-else class="inactive-user">{{ item.role }}</span>
      </template>
      <template #[`item.admin`]="{ item }">
        <v-icon v-if="item.admin" small color="success">mdi-check</v-icon>
        <v-icon v-else small color="error">mdi-close</v-icon>
      </template>
      <template #[`item.tilelytics`]="{ item }">
        <v-icon v-if="item.tilelytics" small color="success">mdi-check</v-icon>
        <v-icon v-else small color="error">mdi-close</v-icon>
      </template>
      <template #[`item.statusLabel`]="{ item }">
        <v-chip :color="statusChipColor(item.status)">{{ item.statusLabel }}</v-chip>
      </template>
      <template #[`item.actions`]="{ item }">
        <wx-btn-icon
          v-if="isDirector"
          :title="$t('common.edit')"
          :to="toUserEdition(item.id)"
          class="my-sm-2 mr-4 mx-md-2 ml-lg-0 mr-lg-4"
          small
        >
          <v-icon small>mdi-pencil</v-icon>
        </wx-btn-icon>
        <!-- 
          Delete btn
          -->
        <dialog-confirm-deletion
          v-if="isDirector && isDeletionAllowed(item)"
          :deletion-title="$t('user.wizard.deletion.title')"
          :info-box-text-title="$t('common.attention')"
          :info-box-text="getUserDeletionInfoText()"
          :confirm-text-prefix="getUserDeletionConfirmationPrefix()"
          :element-display-name="getUserToDeleteDisplayName(item)"
          :delete-button-text="$t('user.wizard.deletion.deleteUserBtn')"
          :small-activator-btn="true"
          @confirm="deleteUser(item)"
          activator-btn-class="my-sm-2 mx-0 mx-md-2 mx-lg-0"
        />
      </template>
      <template #no-data>
        {{ $t("user.listing.noData") }}
      </template>
      <template #[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t("common.listing.itemOfItems") }} {{ items.itemsLength }}
      </template>
    </wx-data-table>
  </fragment>
</template>

<script>
import UserService from "@/components/user/UserService";
import WxDataTable from "@/components/ui/WxDataTable";
import WxTextField from "@/components/ui/WxTextField";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import DialogConfirmDeletion from "@/components/DialogConfirmDeletion";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserList",
  components: {
    WxDataTable,
    WxTextField,
    WxBtnStandard,
    WxBtnIcon,
    DialogConfirmDeletion,
  },
  data() {
    return {
      search: "",
      users: [],
      usersPerPage: 15,
    };
  },

  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("user", ["userId", "isDirector"]),
    computedUserColumns() {
      return [
        { text: this.$t("user.name"), value: "name", class: "col-name" },
        { text: this.$t("user.email"), value: "email", class: "col-email" },
        { text: this.$t("user.role"), value: "role", class: "col-role" },
        { text: this.$t("user.isAdminAbbreviation"), value: "admin", class: "col-admin", align: "center" },
        { text: this.$t("user.isTilelytics"), value: "tilelytics", class: "col-tilelytics", align: "center" },
        { text: this.$t("user.status"), value: "statusLabel", class: "col-status", align: "center" },
        {
          text: this.$t("common.actions"),
          value: "actions",
          class: "col-actions two-btn-wide",
          align: "right",
          sortable: false,
        },
      ];
    },
    computedUsers() {
      return this.users.map((user) => ({
        id: user.id,
        name: user.display_name,
        email: user.email,
        role: this.roleTranslation(user.role),
        originalRoleValue: user.role,
        admin: user.is_admin,
        tilelytics: user.is_tilelytics,
        status: user.status,
        statusLabel: this.statusTranslation(user.status),
      }));
    },
  },
  watch: {
    activeFactory() {
      this.search = "";
      this.users = [];
      this.loadUsers();
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    getRowTitle() {
      return this.$t("user.rowTitle");
    },
    getAvatarText(userDisplayName) {
      return UserService.getAvatarText(userDisplayName);
    },
    isActiveUser(item) {
      return item.status === "active";
    },
    toUserCreation() {
      return RouteService.toUserCreation();
    },
    toUserEdition(userId) {
      return RouteService.toUserEdition(userId);
    },
    redirectToUserEdition(user) {
      this.$router.push(RouteService.toUserEdition(user.id));
    },
    loadUsers() {
      if (this.activeFactory) {
        return UserService.getUsers(this.activeFactory.id)
          .then((response) => this.handleUserRetrievalResponse(response))
          .catch((error) => this.handleRetrievalError(error.response));
      }
    },

    handleUserRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.users = httpResponse.data.filter((u) => !UserService.isWorximityUser(u));
      }
    },

    handleRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },

    getUserDeletionInfoText() {
      return this.$t("user.wizard.deletion.warningMessage");
    },
    getUserDeletionConfirmationPrefix() {
      return this.$t("user.wizard.deletion.confirmMessagePrefix");
    },
    getUserToDeleteDisplayName(userToDelete) {
      return userToDelete.name + " (" + userToDelete.email + ")";
    },
    isSelf(item) {
      return item.id === this.userId;
    },
    isDeletionAllowed(item) {
      return !this.isSelf(item);
    },
    deleteUser(userToDelete) {
      UserService.deleteUser(userToDelete.id)
        .then((response) => this.handleUserDeletionResponse(response, userToDelete.email))
        .catch((error) => this.handleUserDeletionError(error.response));
    },
    handleUserDeletionResponse(httpResponse, email) {
      if (httpResponse.status === 204) {
        this.showOperationSuccess(this.$t("user.wizard.deletion.successfullyDeleted", { email: email }));
        this.loadUsers();
      }
    },
    handleUserDeletionError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },

    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },

    onInvitedUser(userEmail) {
      this.loadUsers().then(() => {
        this.showOperationSuccess(this.$t("user.wizard.creation.successfullyInvited", { email: userEmail }));
      });
    },

    onCreatedUser(email) {
      this.loadUsers().then(() => {
        this.showOperationSuccess(this.$t("user.wizard.creation.successfullyCreated", { email: email }));
      });
    },

    onDeletedUser(email) {
      this.loadUsers().then(() => {
        this.showOperationSuccess(this.$t("user.wizard.deletion.successfullyDeleted", { email: email }));
      });
    },

    onOperationFailure(operationStatusMessages) {
      this.showOperationError(operationStatusMessages);
    },

    roleTranslation(role) {
      return UserService.getDisplayRoleNameForValue(role);
    },

    statusTranslation(status) {
      switch (status) {
        case "active":
          return this.$t("user.statusActive");
        case "inactive":
          return this.$t("user.statusInactive");
        case "invited":
          return this.$t("user.statusInvited");
        default:
          console.warn("Missing case for status " + status);
          return status;
      }
    },

    statusChipColor(status) {
      switch (status) {
        case "active":
          return "primary";
        case "inactive":
          return "error";
        case "invited":
          return "info";
        default:
          console.warn("Missing case for status " + status);
          return "error";
      }
    },
  },

  mounted() {
    this.loadUsers();
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
}

::v-deep table th {
  &.col-name {
    width: 25%;
  }
  &.col-email {
    width: 25%;
  }
  &.col-role {
    width: 14%;
  }
  &.col-admin {
    width: 8%;
  }
  &.col-col-tilelytics {
    width: 8%;
  }
  &.col-status {
    width: 12%;
  }
  &.col-actions {
    width: 8%;
  }
}

.inactive-user {
  color: var(--color-text-disabled-theme);
}
</style>
